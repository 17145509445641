<template>
  <van-nav-bar
  title="库存列表"
  left-arrow
  @click-left="onGoBack"
  />
  <van-tabs v-model:active="active">
    <van-tab title="库存列表">
      <div 
        style=""
        v-for="item in goods.data"
        :key="item.id"
      >
      <van-card
        style="block:inline"
        :title="item.title"
        :desc="item.desc"
        :thumb="item.thumb"
        >
        <template #num>
          <div><span style="font-size:16px;margin-bottom:-100px">库存：{{ item.num }}</span> </div>
        </template>
      </van-card>
      </div>
    </van-tab>
    <van-tab title="进货记录">
      <incomelist />
    </van-tab>
  </van-tabs>
</template>


<script>
import { ref,reactive,getCurrentInstance,onMounted } from 'vue';
import Incomelist from './incomelist.vue';
import { useUserStore } from '@/store/user.ts'
const userStore=useUserStore()
export default {
  components: {
    Incomelist
  },
  setup() {
    
    const goods = reactive({
      data: [{
    }]
    });
    onMounted( () => {
      getstockinfo()
    });
    let { proxy } = getCurrentInstance();
    let tokens={'Authorization':userStore.userInfo.token}
    const getstockinfo=()=>{
      proxy.$axios.get(process.env.VUE_APP_API_PORT_BASE_URL+'/prod/getProdInfo',{headers:tokens})
      .then(res=>{
        console.log(res)
        goods.data.length =0;
        for (var i=0;i<res.data.data.length;i++) {
          console.log(res.data.data[i]);
          goods.data.push({
          id:i,
          title:res.data.data[i].proName,
          proc_id: res.data.data[i].productId,
          price: res.data.data[i].proPrice*100,
          num:100,
          thumb: process.env.VUE_APP_API_PORT_BASE_URL+res.data.data[i].proPic,
          //proc_class: res.data.data.data[i].userJoinDatetime
          })
        }
      }).catch(err=>{
        console.log(err)
      })
    };






    return {
      goods,
      getstockinfo
    };
  },
};
  
</script>
<style lang="less">





</style>
