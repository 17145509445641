<template>
  <van-cell v-for="item in exwarerecord.data" :key="item"  is-link @click="getinfo(item)">
    <van-icon name="todo-list-o" />
    <span style="margin-left:20px">{{item.proCode}}</span>
    <span style="margin-left:20px">{{item.deliveryManName}}</span>
    <span style="margin-left:20px">{{item.recordTime}}</span>
  </van-cell>

  <van-pagination 
    v-model="currentPage"
    :total-items="pagination.totalitems"
    :items-per-page="pagination.itemsperpage"
    @change="onChangePage"
  />
</template>

<script>
import { reactive,onMounted,getCurrentInstance} from 'vue';
import { useUserStore } from '@/store/user.ts'

const base_url=process.env.VUE_APP_API_PORT_BASE_URL

export default {
  setup() {
    const exwarerecord = reactive({data: [{}]});
    const userStore = useUserStore()
    let { proxy } = getCurrentInstance();
    
    let pagination=reactive({
      totalitems:null,
      currentpage:1,
      itemsperpage:15,
    });

    onMounted(() => {
      getincomeinfo()
    });
    const onChangePage=()=>{
      pagination.currentpage=currentPage.value
      //pager.current = pagination.current;
      getuserinfo()
    }

    const getincomeinfo=()=>{
      let params = {data: {}}
      let tokens={'Authorization':userStore.userInfo.token}
      proxy.$axios.post(base_url+'/delivery/getConRecord',params,{headers:tokens})
      .then(res=>{
        console.log(res)
        exwarerecord.data.length=0;
        for (var i=0;i<res.data.data.length;i++) {
          exwarerecord.data.push(res.data.data[i])
        }
        pagination.totalitems = res.data.data.total;
        pagination.itemsperpage=15;
      }).catch(err=>{
        console.log('err'+err)
      })
    };

    return {
      exwarerecord,
      pagination,
      getincomeinfo,
      onChangePage
    };
  },
};
  
</script>
<style lang="less">





</style>
